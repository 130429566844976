import moment from 'moment';
import get from 'lodash/get';
import first from 'lodash/first';
import orderBy from 'lodash/orderBy';
import isEmpty from 'lodash/isEmpty';
import { DEFAULT_IMG_ALT } from './constants';

export const getCurrentYear = () => {
  return new Date().getFullYear();
};

export const isEmptyOrNull = (text) => {
  return isEmpty(text) || isEmpty(first(text).text);
};

export const getImgAlt = (img) => {
  const alt = get(img, 'alt', DEFAULT_IMG_ALT);

  return isEmpty(alt) ? DEFAULT_IMG_ALT : alt;
};

export const formatDate = (date) => {
  return moment(date).format('MMMM D, YYYY');
};

export const filterAllResults = (allResults, categories, data) => {
  const metaData = data.primary && data.primary.meta;

  // If there are no related categories, filter out the current post (if this is a
  // single page) and and then sort by sort Order (if there is one) and then by
  // published date
  let filteredResults;
  if (categories.length === 0) {
    filteredResults = metaData
      ? allResults.filter(
          (result) => result.node.meta.uid !== data.primary.meta.uid
        )
      : allResults;
  } else {
    // If there are related categories, filter to only related categories and then
    // filter out the current post (if this is a single page) and then sort by sort Order
    // (if there is one) and then by published date
    filteredResults = metaData
      ? allResults.filter(
          (result) =>
            (categories.includes(result.node.webCategory) ||
              !!categories.find((category) =>
                get(result, 'node.meta.tags', []).includes(category)
              )) &&
            result.node.meta.uid !== data.primary.meta.uid
        )
      : allResults.filter(
          (result) =>
            categories.includes(result.node.webCategory) ||
            !!categories.find((category) =>
              get(result, 'node.meta.tags', []).includes(category)
            )
        );
  }

  return orderBy(
    filteredResults,
    [
      'node.webSortOrder',
      (item) => item.node.publishDate || item.node.meta.firstPublicationDate,
    ],
    ['asc', 'desc']
  );
};
