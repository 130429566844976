import styled from 'styled-components/macro';
import { theme } from 'styled-tools';

// TODO the following styles are template duplication left overs.  There will be a ticket in the future that will set these and any other values per the project moodboard.

const H1 = styled.h1``;

const H2 = styled.h2``;

const H3 = styled.h3`
  color: ${theme('colors.primary')};
`;

const H4 = styled.h4``;

const H5 = styled.h5``;

const H6 = styled.h6``;

const Subheader = styled.div`
  color: ${theme('colors.black')};
  font-size: 1.5rem;
`;

const P = styled.p``;

const Copy = styled.p`
  color: ${theme('colors.black')};
  font-size: 0.875rem;
  line-height: 1.3125rem;
  margin-bottom: 1.5rem;
`;

const SmallTitle = styled.p`
  color: ${theme('colors.mediumGray')};
  font-size: 0.75rem;
  margin-bottom: 0.375rem;
`;

export { H1, H2, H3, H4, H5, H6, Subheader, P, Copy, SmallTitle };
