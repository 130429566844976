import PropTypes from 'prop-types';

const { string, number, shape } = PropTypes;

export default shape({
  dimensions: shape({
    width: number,
    height: number,
  }),
  alt: string,
  copyright: string,
  url: string,
});
