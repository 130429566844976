import PropTypes from 'prop-types';
import prismicRichTextShape from 'shapes/prismicRichTextShape';

const { shape, string } = PropTypes;

export default shape({
  _linkType: string.isRequired,
  _meta: shape({
    id: string.isRequired,
    lang: string.isRequired,
    uid: string.isRequired,
    type: string.isRequired,
  }),
  url: string,
  name: string,
});
