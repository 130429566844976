import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import prismicLinkShape from 'shapes/prismicLinkShape';
import { getLinkUrl, isInternalLink } from 'utils/prismicHelpers';

const propTypes = {
  link: prismicLinkShape.isRequired,
  children: PropTypes.node,
};

function PrismicLink({
  link,
  children,
  internalAs: InternalComponent = Link,
  internalProps = {},
  externalAs: ExternalComponent = 'a',
  externalProps = {},
  ...props
}) {
  const url = getLinkUrl(link);

  if (isInternalLink(link)) {
    return (
      <InternalComponent to={url} {...internalProps} {...props}>
        {children}
      </InternalComponent>
    );
  }

  return (
    <ExternalComponent href={url} {...externalProps} {...props}>
      {children}
    </ExternalComponent>
  );
}

PrismicLink.propTypes = propTypes;

export default PrismicLink;
