import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import PrismicLink from 'components/PrismicLink';
import prismicImageShape from 'shapes/prismicImageShape';
import prismicLinkShape from 'shapes/prismicLinkShape';
import { getImgAlt } from 'utils/functions';
import { up } from 'styled-breakpoints';

const AppLink = styled(PrismicLink)`
  display: inline-block;
  margin-left: 0.5rem;

  img {
    max-width: 10rem;

    ${up('lg')} {
      max-width: 14.4rem;
    }
  }
`;

const propTypes = {
  appLogo: prismicImageShape.isRequired,
  downloadLink: prismicLinkShape.isRequired,
};

function AppDownload({ appLogo, downloadLink }) {
  return (
    <AppLink
      link={downloadLink}
      internalAs={Link}
      internalProps={{ as: Link }}
      externalAs='a'
      externalProps={{
        target: '_blank',
      }}
    >
      {appLogo && <img alt={getImgAlt(appLogo)} src={appLogo.url} />}
    </AppLink>
  );
}

AppDownload.propTypes = propTypes;

export default AppDownload;
