import React from 'react';
import get from 'lodash/get';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { theme } from 'styled-tools';
import FooterLink from './FooterLink';
import styled from 'styled-components/macro';
import PrismicLink from 'components/PrismicLink';
import linkShape from 'shapes/prismicLinkShape';
import { isIOS, isAndroid } from 'react-device-detect';

const LinkList = styled.ul`
  list-style-type: none;
  margin-bottom: 0;
  padding: 0;
`;

const ListItem = styled.li`
  font-size: 0.875rem;
  margin-bottom: 1rem;

  a {
    color: ${theme('colors.white')};
    font-weight: ${theme('fontWeights.default.light')};

    &:hover {
      color: ${theme('colors.primary')};
      text-decoration: none;
    }
  }
`;

const propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      footerLinkText: PropTypes.string,
      footerLink: linkShape,
      footerLinkSecondary: linkShape
    })
  )
};

function FooterLinkList({ items }) {
  return (
    <LinkList>
      {items.map((item, index) => {

        return (
          <ListItem key={item.footerLinkText}>
            {item.footerLink && item.footerLinkSecondary ? (
              <>
                {isIOS && (
                  <FooterLink
                    link={get(item, 'footerLink')}
                    linkText={get(item, 'footerLinkText')}
                  />
                )}
                {isAndroid && (
                  <FooterLink
                    link={get(item, 'footerLinkSecondary')}
                    linkText={get(item, 'footerLinkText')}
                  />
                )}
              </>
            ) : (
              <FooterLink
                link={get(item, 'footerLink')}
                linkText={get(item, 'footerLinkText')}
              />
            )}
          </ListItem>
        )
      })}
    </LinkList>
  );
};

FooterLinkList.propTypes = propTypes;

export default React.memo(FooterLinkList);
