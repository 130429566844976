import React from 'react';
import styled from 'styled-components/macro';

const ErrorPageHeader = styled.h1`
  margin-bottom: 1.25rem;
`;

const ErrorPageContent = styled.div`
  padding-top: 4rem;
`;

function ErrorBoundaryPage({ error, componentStack, resetErrorBoundary }) {
  return (
    <>
      <ErrorPageHeader>Something went wrong:</ErrorPageHeader>
      <ErrorPageContent>
        <pre>{error.message}</pre>
        <pre>{componentStack}</pre>
      </ErrorPageContent>
    </>
  );
}

export default ErrorBoundaryPage;
