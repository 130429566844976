import { createGlobalStyle } from 'styled-components/macro';
import { prop, theme } from 'styled-tools';
import { down } from 'styled-breakpoints';

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #__gatsby {
    height: 100%;
  }

  body {
    margin: 0;
  }

  #gatsby-focus-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  h1, h2 {
    letter-spacing: 0.71px;
  }

  h1 {
    ${down('lg')} {
      font-size: 2.875rem;
    }

    ${down('md')} {
      font-size: 1.75rem;
    }
  }

  h2 {
    ${down('lg')} {
      font-size: 2.625rem;
    }

    ${down('md')} {
      font-size: 1.70rem;
    }
  }

  h3 {
    ${down('md')} {
      font-size: 1.65rem;
    }
  }

  h4 {
    ${down('md')} {
      font-size: 1.25rem;
    }
  }

  h5 {
    ${down('md')} {
      font-size: 1.15rem;
    }
  }

  h6 {
    ${down('md')} {
      font-size: 0.875rem;
    }
  }

  .header-primary {
    color: ${prop('theme.colors.uiKitBlack')};
  }

  .header-secondary {
    color: ${prop('theme.colors.orange')};
  }

  .header-tertiary {
    color: ${prop('theme.colors.white')};
  }

  .block-img {
    width: inherit;

    img {
      height: auto;
      width: 100%;
    }
  }

  p.link-button {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;

    span {
      display: inline-block;
      padding: 20px;
      background-color: ${prop('theme.colors.red')};
      border-radius: 10px;
      color: white;
    }
  }
`;

export default GlobalStyle;
