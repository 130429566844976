const colors = {
  black: '#000000',
  white: '#ffffff',
  lightGray: '#edede7',
  muted: '#8c8c8c',
  gray: '#464646',
  orange: '#fd8f47',
  darkOrange: '#f55945',
  gold: '#febd6b',
  yellow: '#f3cf88',
  green: '#84a14f',
  darkGreen: '#2e4033',
  armyGreen: '#4f6345',
  highlandGreen: '#668a59',
  azureBlue: '#0084ff',
  blue: '#47616b',
  darkBlue: '#212930',
  purple: '#30304f',
  red: '#cc173d',
  eggplant: '#6e243d',
  darkRed: '#6b0505',
  uiKitBlack: '#222222',
};

const theme = {
  colors: {
    ...colors,
    primary: colors.orange,
    secondary: colors.green,
    success: colors.highlandGreen,
    info: colors.blue,
    warning: colors.yellow,
    danger: colors.red,
    light: colors.lightGray,
    dark: colors.black,
    bodyColor: colors.black,
  },
  breakpoints: {
    xs: '479px',
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
    xxl: '1480px',
  },
  tableBreakpoints: {
    lg: '1040px',
  },
  fonts: {
    default: `'IBM Plex Sans', sans-serif`,
    secondary: `'Roboto', sans-serif`,
    title: '"Roboto", sans-serif',
    headings: '"Roboto", sans-serif',
  },
  fontWeights: {
    default: {
      light: 300,
      regular: 400,
      medium: 500,
      semiBold: 600,
      bold: 700,
    },
    headings: {
      light: 300,
      regular: 400,
      medium: 500,
      bold: 700,
      black: 900,
    },
  },
  buttons: {
    default: {},
    primary: {},
    header: {},
    footer: {},
  },
};

export default theme;
