import React from 'react';
import { Elements } from 'prismic-reactjs';
import { DEFAULT_IMG_ALT } from 'utils/constants';
import linkResolver from 'utils/linkResolver';

// -- Function to add unique key to props
const propsWithUniqueKey = function (props, key) {
  return Object.assign(props || {}, { key });
};

// -- HTML Serializer
// This function will be used to change the way the HTML is loaded
const htmlSerializer = function (type, element, content, children, key) {
  var props = {};

  switch (type) {
    // Update default image alt attribute
    case Elements.image:
      const linkUrl = element.linkTo
        ? element.linkTo.url || linkResolver(element.linkTo)
        : null;
      const linkTarget =
        element.linkTo && element.linkTo.target
          ? { target: element.linkTo.target }
          : {};
      const linkRel = linkTarget.target ? { rel: 'noopener' } : {};
      const img = React.createElement('img', {
        src: element.url,
        alt: element.alt || DEFAULT_IMG_ALT,
      });
      return React.createElement(
        'p',
        propsWithUniqueKey(
          { className: [element.label || '', 'block-img'].join(' ') },
          key
        ),
        linkUrl
          ? React.createElement(
              'a',
              Object.assign({ href: linkUrl }, linkTarget, linkRel),
              img
            )
          : img
      );

    // Return null to stick with the default behavior
    default:
      return null;
  }
};

export default htmlSerializer;
