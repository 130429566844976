import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import BaseButton from 'react-bootstrap/Button';
import { ifProp } from 'styled-tools';

const propTypes = {
  active: PropTypes.bool,
  block: PropTypes.bool,
  variant: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'danger',
    'warning',
    'info',
    'dark',
    'light',
    'link',
    'outline-primary',
    'outline-secondary',
    'outline-success',
    'outline-danger',
    'outline-warning',
    'outline-info',
    'outline-dark',
    'outline-light',
  ]),
  size: PropTypes.oneOf(['sm', 'lg']),
  type: PropTypes.oneOf(['button', 'reset', 'submit']),
  href: PropTypes.string,
  disabled: PropTypes.bool,
  as: PropTypes.elementType,
  forwardedAs: PropTypes.elementType,
  bsPrefix: PropTypes.string,
  extraPadding: PropTypes.bool,
};

const Button = styled(BaseButton)`
  width: fit-content;

  ${ifProp(
    'extraPadding',
    css`
      padding: 0.5rem 2.5rem;
    `
  )}
`;

Button.propTypes = propTypes;

export default Button;
