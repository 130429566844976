/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { graphql, StaticQuery } from 'gatsby';
import { withPreview } from 'gatsby-source-prismic-graphql';
import get from 'lodash/get';

const siteMetaQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        twitterUsername
      }
    }
  }
`;

const defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

const propTypes = {
  description: PropTypes.string,
  lang: PropTypes.object,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

function SEO({
  description,
  lang,
  meta,
  title,
  mastheadImage,
  socialMeta = {},
}) {
  const {
    ogTitle,
    ogImage,
    ogDescription,
    twitterCard,
    twitterImage,
    twitterTitle,
    twitterDescription,
    twitterPlayer,
    twitterPlayerWidth,
    twitterPlayerHeight,
  } = socialMeta;
  return (
    <StaticQuery
      query={siteMetaQuery}
      render={withPreview(({ site }) => {
        const metaDescription = description || site.siteMetadata.description;

        const additionalMeta = [];

        if (twitterPlayer && twitterCard === 'player') {
          additionalMeta.push({
            name: 'twitter:player',
            content: twitterPlayer,
          });
          additionalMeta.push({
            name: 'twitter:player:width',
            content: twitterPlayerWidth || '1280',
          });
          additionalMeta.push({
            name: 'twitter:player:height',
            content: twitterPlayerHeight || '720',
          });
        }

        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={title}
            titleTemplate={`%s | ${site.siteMetadata.title}`}
            meta={[
              {
                name: `description`,
                content: metaDescription,
              },
              {
                property: `og:title`,
                content: ogTitle || title,
              },
              {
                property: `og:image`,
                content: get(ogImage, 'url') || get(mastheadImage, 'url'),
              },
              {
                property: `og:description`,
                content: ogDescription || metaDescription,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                name: `twitter:card`,
                content: twitterCard || `summary`,
              },
              {
                name: `twitter:creator`,
                content: site.siteMetadata.twitterUsername,
              },
              {
                name: `twitter:image`,
                content: get(twitterImage, 'url') || get(mastheadImage, 'url'),
              },
              {
                name: `twitter:title`,
                content: twitterTitle || title,
              },
              {
                name: `twitter:description`,
                content: twitterDescription || metaDescription,
              },
              ...additionalMeta,
            ].concat(meta)}
          />
        );
      }, siteMetaQuery)}
    />
  );
}

SEO.defaultProps = defaultProps;

SEO.propTypes = propTypes;

export default SEO;
