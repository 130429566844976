import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { graphql, Link, StaticQuery } from 'gatsby';
import { withPreview } from 'gatsby-source-prismic-graphql';
import imageShape from 'shapes/prismicImageShape';
import linkShape from 'shapes/prismicLinkShape';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import BaseContainer from 'react-bootstrap/Container';
import HeaderLogo from './HeaderLogo';
import PrismicLink from 'components/PrismicLink';
import { theme } from 'styled-tools';

const Container = styled(BaseContainer)`
  max-width: 95%;
`;

const NavItem = styled(Nav.Item)`
  font-weight: ${theme('fontWeights.default.medium')};
`;

const propTypes = {
  data: PropTypes.shape({
    prismic: PropTypes.shape({
      allHeaders: PropTypes.shape({
        edges: PropTypes.arrayOf(
          PropTypes.shape({
            node: PropTypes.shape({
              logo: imageShape,
              navMenu: PropTypes.arrayOf(
                PropTypes.shape({
                  navLinkText: PropTypes.string,
                  navLink: linkShape,
                })
              ),
            }),
          })
        ),
      }),
    }),
  }),
};

function Header({ data }) {
  const doc = data.prismic.allHeaders.edges.slice(0, 1).pop();
  if (!doc) {
    return null;
  }

  const node = doc.node;

  return (
    <header>
      <Navbar bg='dark' variant='dark' expand='lg'>
        <Container>
          <Navbar.Brand>
            <Link to='/'>
              <HeaderLogo logoImg={node.logo.url} />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls='basic-navbar-nav' />
          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav className='ml-auto'>
              {node.navMenu.map((navItem, index) => (
                <NavItem key={navItem.navLinkText}>
                  <PrismicLink
                    link={navItem.navLink}
                    internalAs={Nav.Link}
                    internalProps={{ activeClassName: 'active', as: Link }}
                    externalAs={Nav.Link}
                    externalProps={{
                      target: '_blank',
                      eventKey: `link-${index}`,
                    }}
                  >
                    {navItem.navLinkText}
                  </PrismicLink>
                </NavItem>
              ))}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}

Header.propTypes = propTypes;

const headerQuery = graphql`
  query {
    prismic {
      allHeaders {
        edges {
          node {
            logo: header_nav_logo
            title: header_nav_title
            navMenu: nav_menu {
              navLink: nav_link {
                ...LinkFragment
              }
              navLinkText: nav_link_text
            }
          }
        }
      }
    }
  }
`;

export default (props) => (
  <StaticQuery
    query={headerQuery}
    render={withPreview(
      (data) => (
        <Header data={data} {...props} />
      ),
      headerQuery
    )}
  />
);
