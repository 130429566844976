import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { theme } from 'styled-tools';
import styled from 'styled-components/macro';
import PrismicLink from 'components/PrismicLink';
import linkShape from 'shapes/prismicLinkShape';

const propTypes = {
  link: linkShape,
  linkText: PropTypes.string,
};

function FooterLink({ link, linkText }) {
  return (
    <PrismicLink
      link={link}
      internalAs={Link}
      internalProps={{ as: Link }}
      externalAs='a'
      externalProps={{
        target: '_blank',
      }}
    >
      {linkText}
    </PrismicLink>
  );
};

FooterLink.propTypes = propTypes;

export default React.memo(FooterLink);
