import PropTypes from 'prop-types';

const { shape, arrayOf, string, number } = PropTypes;

export default arrayOf(
  shape({
    type: string.isRequired,
    text: string,
    spans: arrayOf(
      shape({
        start: number,
        end: number,
        type: string,
        text: string,
      })
    ),
  })
);
