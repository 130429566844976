import React from 'react';
import { RichText as BaseRichText } from 'prismic-reactjs';
import linkResolver from 'utils/linkResolver';
import prismicRichTextShape from 'shapes/prismicRichTextShape';
import htmlSerializer from './htmlSerializer';

const propTypes = {
  render: prismicRichTextShape,
};

function RichText({ render, ...props }) {
  return (
    <BaseRichText
      render={render}
      linkResolver={linkResolver}
      htmlSerializer={htmlSerializer}
      {...props}
    />
  );
}

RichText.propTypes = propTypes;

export default RichText;
