import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { down } from 'styled-breakpoints';

const HeaderImg = styled.img`
  height: 3.125rem;
  width: auto;

  ${down('sm')} {
    height: 2.5rem;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const propTypes = {
  logoImg: PropTypes.string.isRequired,
};

function HeaderLogo({ logoImg }) {
  return (
    <ImageContainer>
      <HeaderImg src={logoImg} alt={'Dough'} />
    </ImageContainer>
  );
}

HeaderLogo.propTypes = propTypes;

export default React.memo(HeaderLogo);
