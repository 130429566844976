import PropTypes from 'prop-types';
import prismicRichTextShape from 'shapes/prismicRichTextShape';
import prismicLinkShape from './prismicLinkShape';
import prismicImageShape from './prismicImageShape';

const { shape, arrayOf, bool } = PropTypes;

export default shape({
  title: prismicRichTextShape,
  socialMedias: arrayOf(
    shape({
      icon: prismicImageShape,
      name: prismicRichTextShape,
      link: prismicLinkShape,
      show: bool,
    })
  ),
});
