import PropTypes from 'prop-types';
import prismicLinkShape from './prismicLinkShape';
import prismicImageShape from 'shapes/prismicImageShape';

const { shape, string } = PropTypes;

export default shape({
  type: string.isRequired,
  label: string,
  primary: shape({
    appStoreImage: prismicImageShape,
    googlePlayImage: prismicImageShape,
    appStoreLink: prismicLinkShape,
    googlePlayLink: prismicLinkShape,
  }),
});
