import linkResolver from 'utils/linkResolver';

export function isInternalLink(link) {
  return (
    link !== null && link !== undefined && link._linkType === 'Link.document'
  );
}

export function getLinkUrl(link) {
  if (link === null || link === undefined) {
    return '';
  }

  if (isInternalLink(link) && link._meta) {
    return linkResolver(link._meta);
  }

  return link.url;
}
